<template>
  <v-container fluid class="pl-12 pr-12">
    <v-layout v-show="!isLoading && !isAvailable" column wrap>
      <p class="mt-2">{{ $t('description.agencyNotFound') }}</p>
      <v-btn
        class="font-weight-black float-right mr-4"
        width="70vw"
        max-width="350px"
        color="next"
        style="font-size:20px"
        dark
        @click="onClickiBackHomeButton()"
        >{{ $t('button.backHome') }}</v-btn
      >
    </v-layout>

    <v-layout v-show="!isLoading && isAvailable" column wrap>
      <v-row>
        <v-col>
          <p class="font-weight-black pl-3" style="font-size:32px">
            {{ $t('title.agency.agencyDetail') }}
          </p>
        </v-col>
      </v-row>
      <v-row>
        <p class="pl-5" style="word-wrap: break-all; max-width:90vw">
          {{ $t('description.agencyDetailTop.explanation1') }}
        </p>
      </v-row>
      <v-row>
        <v-col>
          <v-row>
            <v-col class="pb-0">
              <p
                class="pl-5 mt-3 mb-0 font-weight-black"
                style="font-size:20px;"
              >
                {{ $t('label.agencyInfo') }}
              </p>
            </v-col>
            <v-col class="pb-0">
              <v-btn
                class="font-weight-black float-right"
                align="center"
                width="70vw"
                max-width="250px"
                color="next"
                style="font-size:20px"
                :dark="isAgencyEditButtonEnabled"
                :disabled="!isAgencyEditButtonEnabled"
                slot="activator"
                @click="onClickEditAgencyButton()"
                >{{ $t('button.agencyEdit') }}</v-btn
              >
            </v-col>
            <v-col class="pb-0">
              <v-btn
                class="font-weight-black float-right"
                align="center"
                width="70vw"
                max-width="250px"
                color="next"
                style="font-size:20px"
                :dark="isAgencyDeleteButtonEnabled"
                :disabled="!isAgencyDeleteButtonEnabled"
                @click="onClickAgencyDeleteButton()"
                >{{ $t('button.agencyDelete') }}</v-btn
              >
            </v-col>
          </v-row>

          <v-simple-table
            class="elevation-1 v_base_table_fill_width v_base_table_with_border"
            style="width: 100%;"
          >
            <tbody>
              <tr v-for="agencyRecord in agencyRecords" :key="agencyRecord.key">
                <td
                  class="text-left .v_base_table_with_border base_td_category_text"
                >
                  {{ $t(`header.agencyListTable.${agencyRecord.key}`) }}
                </td>
                <td>{{ agencyRecord.value }}</td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-col>
      </v-row>
      <!-- 代理店編集ダイアログ -->
      <FormsDialog
        :showDialog="showEditAgencyDialog"
        :title="$t('title.agency.agencyEdit')"
        :text="null"
        :subText="null"
        :negativeButtonTitle="$t('button.cancel')"
        :positiveButtonTitle="$t('button.update')"
        :onClickNegativeButton="cancelEditAgency"
        :onClickPositiveButton="updateAgency"
        :targets="editAgencyTargets"
      />
      <!-- 代理店削除ダイアログ -->
      <DeleteDialog
        :title="$t('title.agency.agencyDelete')"
        @onSuccess="onSuccessDeleteAgency"
        ref="agencyDeleteDialog"
      />
      <CompletedDialog ref="completedDialog"></CompletedDialog>
      <ErrorDialog ref="errorDialog"></ErrorDialog>
    </v-layout>
  </v-container>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import { ErrorCode, MaxLength } from '@/lib/const';
import ErrorDialog from '@/components/organisms/agency/ErrorDialog';
import FormsDialog from '@/components/organisms/agency/FormsDialog';
import DeleteDialog from '@/components/organisms/agency/DeleteDialog';
import CompletedDialog from '@/components/organisms/agency/CompletedDialog';
import { Role } from '@/lib/role';
import { getAgency, putAgency, deleteAgency } from '@/apis/agency/agencies';
import { getLoginUser } from '@/apis/agency/users';

export default {
  name: 'AgencyDetail',
  components: {
    ErrorDialog,
    FormsDialog,
    DeleteDialog,
    CompletedDialog,
  },
  data: vm => ({
    // 代理店編集データ (デフォルト)
    editAgencyDefaultTargets: [
      {
        value: null,
        targetName: 'agencyCode',
        type: 'text',
        label: vm.$t('header.agencyListTable.agencyCode'),
        text: null,
        rule: `required|noSpace|pattern:agencyCode`,
        key: 'agencyCode',
      },
      {
        value: null,
        targetName: 'agencyName',
        type: 'text',
        label: vm.$t('header.agencyListTable.agencyName'),
        text: null,
        rule: `required|max:${MaxLength.Default}`,
        key: 'agencyName',
      },
    ],

    // 代理店編集データ
    editAgencyTargets: [],

    // 代理店編集ダイアログが表示されているかどうか
    showEditAgencyDialog: false,

    // 代理店データ
    agency: {},

    // 代理店コード
    agencyCode: '',

    // 代理店情報
    agencyRecords: [],

    // 代理店編集ボタンが有効であるかどうか
    isAgencyEditButtonEnabled: false,

    // 代理店削除ボタンが有効であるかどうか
    isAgencyDeleteButtonEnabled: false,
    // ローディング中であるかどうか
    isLoading: true,

    // 有効であるかどうか
    isAvailable: true,
  }),
  async mounted() {
    // 代理店の取得
    await this.fetchAgency();
  },
  methods: {
    ...mapGetters('user', ['userDetail']),
    ...mapActions('user', ['setUserDetail']),

    // 代理店の取得
    async fetchAgency() {
      // 代理店を取得
      const agency = await getAgency(this.$route.params['agency_id']).catch(
        () => {
          this.$set(this, 'isAvailable', false);
        }
      );
      this.$set(this, 'isLoading', false);

      // 代理店が取得できなかった場合、以降の処理を中止する
      if (!agency) return;

      // 代理店編集ボタンの有効状態をセットする
      this.$set(
        this,
        'isAgencyEditButtonEnabled',
        Role.isButtonEnabled(this.userDetail(), 'agencyEdit', {
          officeId: agency.id,
        })
      );
      // 代理店削除ボタンの有効状態をセットする
      this.$set(
        this,
        'isAgencyDeleteButtonEnabled',
        Role.isButtonEnabled(this.userDetail(), 'agencyDelete', {
          officeId: agency.id,
        })
      );

      // 代理店データをセットする
      this.$set(this, 'agency', agency);

      // 代理店情報表示の更新
      this.$set(this, 'agencyRecords', this.mapAgency(agency));
    },

    // 代理店データのマッピング
    mapAgency(agency) {
      return [
        { key: 'agencyCode', value: agency.agencyCode },
        { key: 'agencyName', value: agency.agencyName },
        {
          key: 'salesCode',
          value: agency.sale ? agency.sale.salesCode : null,
        },
        {
          key: 'salesDepartmentName',
          value: agency.sale ? agency.sale.salesDepartmentName : null,
        },
        {
          key: 'salesSectionName',
          value: agency.sale ? agency.sale.salesSectionName : null,
        },
      ];
    },

    // 代理店編集キャンセル
    cancelEditAgency() {
      this.$set(this, 'showEditAgencyDialog', false);
    },

    // 代理店更新
    async updateAgency(item) {
      const agency = {
        agencyCode:
          this.agencyCode !== item.agencyCode ? item.agencyCode : undefined, // 値に変更がない場合、送信しない
        agencyName: item.agencyName,
      };
      // 代理店の更新
      const result = await putAgency(this.agency.id, agency).catch(error => {
        let message;
        switch (error.code) {
          case ErrorCode.DuplicateAgencyCode:
            message = this.$t('error.duplicateAgencyCode', {agencyCode:item.agencyCode});
            break;
          default:
            message = this.$t('error.agencyUpdateBadRequest');
        }
        this.$refs.errorDialog.open(
          this.$t('title.agency.agencyUpdateError'),
          message
        );
      });

      // 代理店が更新できなかった場合、以降の処理を中止する
      if (!result) return;

      await this.fetchAgency();

      // ストア情報の更新
      const loginUserInfo = await getLoginUser().catch(() => {});
      this.setUserDetail(loginUserInfo);

      this.$set(this, 'showEditAgencyDialog', false);
      this.$refs.completedDialog.open(
        this.$t('title.agency.updateResult'),
        this.$t('success.updated')
      );
    },

    // 代理店情報編集ボタン押下時
    async onClickEditAgencyButton() {
      this.$set(this, 'agencyCode', this.agency.agencyCode);

      const agency = {
        agencyCode: this.agency.agencyCode,
        agencyName: this.agency.agencyName,
      };
      const editAgencyTargets = JSON.parse(
        JSON.stringify(this.editAgencyDefaultTargets)
      )
        .filter(target => Role.canEdit(this.userDetail().role, target.key))
        .map(target => {
          target.value = agency[target.targetName];
          return target;
        });

      this.$set(this, 'editAgencyTargets', editAgencyTargets);
      this.$set(this, 'showEditAgencyDialog', true);
    },

    // 代理店削除ボタン押下時
    async onClickAgencyDeleteButton() {
      this.$refs.agencyDeleteDialog.open();
    },

    // 代理店削除成功時
    async onSuccessDeleteAgency() {
      // 代理店の削除
      const result = await deleteAgency(this.agency.id).catch(() => {
        this.$refs.errorDialog.open(
          this.$t('title.agency.agencyDeleteError'),
          this.$t('error.agencyDeleteBadRequest')
        );
      });

      // 代理店が削除できなかった場合、以降の処理を中止する
      if (!result) return;

      this.$refs.agencyDeleteDialog.close();

      // 代理店一覧画面に遷移する
      this.$router.push('/agency_list');
    },

    // ホーム画面に戻るボタン押下時
    onClickiBackHomeButton() {
      // ホーム画面に遷移する
      this.$router.push('/Home');
    },
  },
};
</script>
<style>
.v_data_table_fill_width {
  width: 100%;
}
thead.v-data-table-header {
  background-color: #ddebf7;
}
thead.v-data-table-header > tr > th {
  font-size: 14px !important;
  text-align: center !important;
  padding: 0;
  white-space: pre-wrap;
}
.v_footer_justify_evenly {
  justify-content: space-evenly;
}
.v-data-table-header__icon {
  /* テーブルのソートアイコンを常に表示 */
  opacity: 1 !important;
  color: rgba(0, 0, 0, 0.2) !important;
}
th.active > .v-data-table-header__icon {
  color: rgba(0, 0, 0, 1) !important;
}
.v_base_table_with_border {
  border: solid 1px;
}
.v_base_table_fill_width {
  width: 50%;
}
.base_td_category_text {
  width: 230px;
  background: #cccccc;
}
.v-data-table td {
  word-break: break-all;
}
.v-data-table {
  overflow: hidden;
}
</style>
